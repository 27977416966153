<template>
  <b-overlay :show="loading">
    <div class="formBoder">
    <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
      <b-form @submit.prevent="handleSubmit(submitData)" @reset.prevent="reset" autocomplete="off">
        <b-row>
          <b-col xs="12" sm="12" md="12" lg="12" xl="12">
            <ValidationProvider name="Name (En)" vid="name_en" rules="required">
                <b-form-group
                  class="row"
                  label-cols-sm="12"
                  label-for="name_en"
                  slot-scope="{ valid, errors }"
                >
                <template v-slot:label>
                  Name (En)<span class="text-danger">*</span>
                </template>
                  <b-form-input
                    type="text"
                    id="name_en"
                    v-model="form.name_en"
                    placeholder="Enter Name (En)"
                    :state="errors[0] ? false : (valid ? true : null)"
                  ></b-form-input>
                  <div class="invalid-feedback">
                  {{ errors[0] }}
                  </div>
                </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col xs="12" sm="12" md="12" lg="12" xl="12">
            <ValidationProvider name="Name (Bn)" vid="name_bn" rules="required">
                <b-form-group
                  class="row"
                  label-cols-sm="12"
                  label-for="name_bn"
                  slot-scope="{ valid, errors }"
                >
                <template v-slot:label>
                  Name (Bn)<span class="text-danger">*</span>
                </template>
                  <b-form-input
                    type="text"
                    id="name_bn"
                    v-model="form.name_bn"
                    placeholder="Enter Name (Bn)"
                    :state="errors[0] ? false : (valid ? true : null)"
                  ></b-form-input>
                  <div class="invalid-feedback">
                  {{ errors[0] }}
                  </div>
                </b-form-group>
            </ValidationProvider>
          </b-col>
        </b-row>
      <div class="row mt-3">
        <div class="col-sm-3"></div>
          <div class="col text-right">
            <b-button type="submit" variant="primary" class="mr-2">{{ SaveButton }}</b-button>
            &nbsp;
            <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-1')">Cancel</b-button>
          </div>
      </div>
     </b-form>
    </ValidationObserver>
    </div>
  </b-overlay>
</template>
<script>
import RestApi, { apiBaseURL } from '@/config'

export default {
  props: ['editItem'],
  components: {
  },
  data () {
    return {
      SaveButton: this.editItem ? 'Update' : 'Save',
      form: {
        id: '',
        name_en: '',
        name_bn: ''
      },
      errors: [],
      valid: null,
      loading: false,
      sectionList: []
    }
  },
  created () {
    if (this.editItem) {
      this.form = this.editItem
    }
    this.getBookParentListDropDown()
  },
  computed: {
    pageTypeList: function () {
      const list = [
        { value: 'preface', text: 'preface' },
        { value: 'main_body', text: 'Main Body' },
        { value: 'table_of_contents', text: 'Table of Contents' }
      ]
      return list
    }
  },
  methods: {
    async submitData () {
      this.loading = true
      let result = ''
      if (this.form.id) {
        result = await RestApi.postData(apiBaseURL, 'admin/ajax/update_tag_data', this.form)
      } else {
        result = await RestApi.postData(apiBaseURL, 'admin/ajax/store_tag_data', this.form)
      }
      if (result.success) {
        this.$emit('loadList', true)
        this.$toast.success({
          title: 'Success',
          message: result.message
        })
        this.$bvModal.hide('modal-1')
        this.loading = false
      } else {
        this.$refs.form.setErrors(result.errors)
      }
    }
  }
}
</script>
<style>
 .formBoder {
    border: 1px solid;
    margin: 5px;
    padding: 35px;
    font-size: 13px
 }
 .input-group-text {
   height: 30.5px!important;
 }
</style>
